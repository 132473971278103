import { createContext } from 'react';
import { IAppContext } from '../models/interfaces/IAppContext';

const AppContext = createContext<IAppContext>({
	dataUser: {
		usuario: null,
		roles: [],
	},
	setDataUser: () => {},
});

export default AppContext;
