import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ContacLogo from '../assets/images/contac.png';
import { ROUTES } from '../constants/Routes';
import useAppData from '../hooks/useAppData';
import { logoutService } from '../services/auth.service';

import '../styles/components/NavMenu.component.scss';

export default function NavMenu() {
	const { setDataUser } = useAppData();

	return (
		<Navbar
			className="navmenu px-3"
			bg="primary"
			expand="lg"
			variant="dark"
			sticky="top"
			collapseOnSelect
		>
			<Container fluid>
				<Navbar.Brand as={Link} to={ROUTES.HOME}>
					<img src={ContacLogo} width="50%" alt="Contac" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<div className="navmenu__nav-items">
						<Nav>
							<Nav.Link onClick={() => logoutService(setDataUser)}>
								Cerrar Sesión
							</Nav.Link>
						</Nav>
					</div>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
