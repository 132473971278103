import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import NavMenuComponent from './components/NavMenuComponent';
import { ROUTES } from './constants/Routes';
import AppContext from './context/AppContext';
import { IAppContext } from './models/interfaces/IAppContext';
import { IUsuarioSesionContext } from './models/interfaces/IUsuarioSesionContext';

import HomePage from './views/HomePage/HomePage';
import LoginPage from './views/LoginPage/LoginPage';

function App() {
	const [dataUser, setDataUser] = useState<IUsuarioSesionContext>({
		usuario: JSON.parse(localStorage.getItem('credenciales'))?.usuario,
		roles: JSON.parse(localStorage.getItem('credenciales'))?.roles,
	});

	const contextData: IAppContext = {
		dataUser,
		setDataUser,
	};

	const navegacion = pagina => {
		if (!!dataUser.usuario) {
			return pagina;
		} else {
			return <Navigate to={ROUTES.ROOT} />;
		}
	};

	return (
		<AppContext.Provider value={contextData}>
			<main className="d-flex flex-column justify-content-between">
				{!!dataUser.usuario && (
					<>
						<NavMenuComponent />
					</>
				)}
				<div className="flex-grow-1">
					<Routes>
						<Route
							path={ROUTES.ROOT}
							element={
								!!dataUser.usuario ? (
									<Navigate to={ROUTES.HOME} />
								) : (
									<LoginPage />
								)
							}
						/>
						<Route path={ROUTES.HOME} element={navegacion(<HomePage />)} />
					</Routes>
				</div>
			</main>
		</AppContext.Provider>
	);
}

export default App;
