import { IAuth } from '../models/interfaces/IAuth';
import { IUsuarioSesionContext } from '../models/interfaces/IUsuarioSesionContext';
import http from '../utils/http';

export const authService = async (
	dataRequest: IAuth,
	addDataUser: Function
) => {
	try {
		const { data } = await http.post('Auth/login', dataRequest);
		localStorage.setItem('credenciales', JSON.stringify(data));
		addDataUser((userData: IUsuarioSesionContext) => ({
			...userData,
			usuario: data.usuario,
			roles: data.roles.map(role => role.toLowerCase()),
		}));
	} catch (ex) {
		throw ex;
	}
};

export const logoutService = (removeDataUser: Function) => {
	removeDataUser(() => ({
		usuario: null,
		roles: [],
	}));
	localStorage.removeItem('credenciales');
};
