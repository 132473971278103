import http from '../utils/http';

export const buscarTrabajadoService = async (cardId: number) => {
	try {
		const result = await http.post(`CardReader/${cardId}`, {
			cardId: `${cardId}`,
		});
		return result;
	} catch (ex) {
		throw ex;
	}
};
