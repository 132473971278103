export const ErrorMessages = {
	Required: 'El campo es obligatorio',
	AtLeastOne: (min: string, items: string = 'caracteres') =>
		`El campo debe tener al menos ${min} ${items}`,
	InvalidValue: (condicion: string, subcadena: string = 'valor') =>
		`El ${subcadena} no es válido, deben ser ${condicion}`,
	LengthValue: (lengthVal: number) =>
		`El valor debe tener ${lengthVal} caracter${lengthVal > 1 ? 'es' : ''}`,
	InvalidEmail: 'El correo no es válido',
};
