import axios from 'axios';

const baseURL = process.env.REACT_APP_API;
const instancia = axios.create({
	baseURL,
});

instancia.interceptors.request.use(
	response => {
		const credenciales = localStorage.getItem('credenciales');
		if (credenciales) {
			const objetoCredenciales = JSON.parse(credenciales);
			response.headers.Authorization = `Bearer ${objetoCredenciales.token}`;
		}
		return response;
	},
	error => {
		return Promise.reject(error);
	}
);

//i Interceptamos las respuestas
instancia.interceptors.response.use(
	response => response,
	error => {
		if (error.response.status === 401 && window.location.pathname !== '/') {
			localStorage.removeItem('credenciales');
			window.location.href = '/';
		}
		throw error;
	}
);

export default instancia;
